import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AgGridCommService } from '../services/ag-grid-comm.service';
import { journal } from '@icons';

@Component({
    selector: 'climb-cell-journal-link',
    template: `
        <a data-automation-id="journal-button"
           climbButton
           variant="ghost"
           size="sm"
           (click)="journalClicked()">
            <svg [climbIcon]="icons.journal"></svg>
        </a>
    `,
    styleUrls: ['./cell-journal-link.component.scss'],
})
export class CellJournalLinkComponent implements ICellRendererAngularComp {
    public icons = { journal };
    public params: ICellRendererParams;

    constructor(
        private agGridCommService: AgGridCommService,
    ) { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    /**
     * Required by ICellRenderedAngularComp.
     * Called on cell refresh by ag-grid
     * @param params
     */
    refresh(params: any): boolean {
        this.params = params;
        return true;
    }

    journalClicked(): void {
        // pipe event to parent data-table component
        this.agGridCommService.journalLinkClicked(this.params.data);
    }
}
